import { useRouter } from 'next/router';
import { SliceZone } from '@prismicio/react';
import * as Prismic from '@prismicio/client';
import { GetStaticPropsContext } from 'next';
import { Shell } from '../components/shell';
import { createClient } from '../prismic-configuration';
import { MetaData } from '../components/seo/metadata';
import PageLoader from '../components/page-loader';
import dynamic from 'next/dynamic';
import { MerchantFormProviderWrapper } from '@ee-monorepo/shared/context/merchant-form';
import { components } from '@ee-monorepo/prismic/feature-slices/all-slices';
import { PageSEOProvider } from '@ee-monorepo/shared/utilities/hooks';
import { BreadcrumbJsonLd, SocialProfileJsonLd, WebPageJsonLd } from 'next-seo';
import { useTranslations } from 'next-intl';

const MerchantShortFormDialog = dynamic(
  import('@ee-monorepo/merchant-dialogs/merchant-short-form-dialog').then(
    (module) => module.MerchantShortFormDialog
  )
);
const MerchantLongFormDialog = dynamic(
  import('@ee-monorepo/merchant-dialogs/merchant-long-form-dialog').then(
    (module) => module.MerchantLongFormDialog
  )
);

function StackPage({ slices, page, layout }) {
  const router = useRouter();
  const t = useTranslations();
  if (router.isFallback) {
    return <PageLoader />;
  }
  if (!page.id) {
    router.push('/404');
    return null;
  }
  const { title, description, noindex, show_customer_links } = page.data;
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';



  return (
    <>
      {page.uid === 'about-us' ? (
        <>
          <WebPageJsonLd
            scriptId="about-us-seo"
            id={t('shared.about_us_page_schema.web_page_schema.id')}
            description={t(
              'shared.about_us_page_schema.web_page_schema.description'
            )}
            lastReviewed={t(
              'shared.about_us_page_schema.web_page_schema.last_reviewed'
            )}
            reviewedBy={{
              name: t('shared.about_us_page_schema.web_page_schema.reviewedBy'),
            }}
          />
          <SocialProfileJsonLd
            scriptId="about-us-organization-seo"
            type="Organization"
            name={t('shared.about_us_page_schema.social_schema.name')}
            url={t('shared.about_us_page_schema.social_schema.url')}
            sameAs={[
              t('shared.about_us_page_schema.social_schema.facebook_link'),
              t('shared.about_us_page_schema.social_schema.instagram_link'),
              t('shared.about_us_page_schema.social_schema.twitter_link'),
              t('shared.about_us_page_schema.social_schema.linkedin_link'),
            ]}
          />
          <BreadcrumbJsonLd
            scriptId="about-us-breadcrumb-seo"
            itemListElements={[
              {
                position: 1,
                item: {
                  '@id': origin,
                  name: 'Home',
                },
              },
              {
                position: 2,
                item: {
                  '@id': `${origin}${router.asPath}`,
                  name: 'About',
                },
              },
            ]}
          />
        </>
      ) : null}
      <PageSEOProvider
        values={{ title, description, noindex, show_customer_links }}
      >
        <MetaData layout={layout} page={page} path={router.asPath} />
        <MerchantFormProviderWrapper>
          <Shell layout={layout} banner={page.data?.banner}>
            <SliceZone slices={slices} components={components} />
            <MerchantLongFormDialog />
            <MerchantShortFormDialog />
          </Shell>
        </MerchantFormProviderWrapper>
      </PageSEOProvider>
    </>
  );
}

export const getStaticPaths = async () => {
  const client = createClient();
  // only need uid here from api call
  // max page size 5 to keep build fast
  const queryResult = await client.get({
    predicates: [Prismic.predicate.at('document.type', 'stack')],
    fetch: ['stack.uid'],
    pageSize: 5,
  });
  return {
    paths: queryResult.results.map((doc) => ({ params: { uid: doc.uid } })),
    fallback: true,
  };
};

export const getStaticProps = async ({
  locale,
  params,
  previewData,
}: GetStaticPropsContext<{ uid: string }, any>) => {
  const client = createClient({ previewData });
  try {
    const queryResult = await client.getByUID<any>('stack', params.uid);
    return {
      props: {
        page: queryResult,
        slices: queryResult?.data?.slices,
        messages: {
          page: { ...require(`../i18n/pages/stack/${locale}.json`) },
          shared: { ...require(`../i18n/shared/${locale}.json`) },
          ...(
            await import('@ee-monorepo/forms/merchant-business-form')
          ).getTranslations(locale),
          ...(
            await import('@ee-monorepo/forms/merchant-financial-form')
          ).getTranslations(locale),
          ...(
            await import('@ee-monorepo/forms/merchant-products-services-form')
          ).getTranslations(locale),
          ...(
            await import('@ee-monorepo/forms/merchant-short-form')
          ).getTranslations(locale),
          ...(
            await import(
              '@ee-monorepo/merchant-dialogs/merchant-short-form-dialog'
            )
          ).getTranslations(locale),
          ...(
            await import(
              '@ee-monorepo/merchant-dialogs/merchant-long-form-dialog'
            )
          ).getTranslations(locale),
          ...(
            await import('@ee-monorepo/forms/merchant-lead-form')
          ).getTranslations(locale),
        },
      },
      revalidate: 30, // TODO: adjust revalidation time this later
    };
  } catch {
    return { notFound: true, revalidate: 30 };
  }
};

export default StackPage;
